export const artikel1 = require('../galery/artikel1.png');
export const aruna1 = require('../galery/aruna1.png');
export const aruna2 = require('../galery/aruna2.png');
export const aruna3 = require('../galery/aruna3.png');
export const image1 = require('../galery/image1.png');
export const image2 = require('../galery/image2.png');
export const image3 = require('../galery/image3.png');
export const image4 = require('../galery/image4.png');
export const image5 = require('../galery/image5.png');
export const image10 = require('../galery/image10.png');
export const image22 = require('../galery/image22.png');
export const Logo = require('../galery/Logo.png');
export const pendiri = require('../galery/pendiri.png');
export const Service = require('../galery/Service.png');
export const bglayanan = require('../galery/bglayanan.png');
export const aruna22 = require('../galery/aruna22.png');
export const plane2 = require('../galery/plane2.png');
