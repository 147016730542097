import React from "react";

type Props = {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "large" | "small";
  customClass?: string;
};

const Text = ({ children, variant, customClass }: Props) => {
  // Define the default classes for headings
  const baseClasses = "text-[#252525] text-xl";

  // Define variant-specific classes
  let variantClasses = "";

  switch (variant) {
    case "primary":
      variantClasses = "text-[20px] sm:text-[36px]"; // Define your Tailwind CSS classes for the 'primary' variant
      break;
    case "secondary":
      variantClasses = "text-secondary"; // Define your Tailwind CSS classes for the 'secondary' variant
      break;
    case "large":
      variantClasses = "text-lg"; // Define your Tailwind CSS classes for the 'large' variant
      break;
    case "small":
      variantClasses = "text-sm"; // Define your Tailwind CSS classes for the 'small' variant
      break;
    default:
      variantClasses = ""; // No variant specified
  }

  // Combine base and variant classes
  const combinedClasses = `${baseClasses} ${variantClasses} ${customClass}`;

  // Render the heading element with the specified type and combined classes
  return <p className={combinedClasses}>{children}</p>;
};

export default Text;
