import Container from "../components/atoms/Container";
import EmbededMapMedia from "../components/atoms/EmbededMapMedia";
import Heading from "../components/atoms/Heading";
import Image from "../components/atoms/Image";
import LinkWithIcon from "../components/atoms/LinkWithIcon";
import Text from "../components/atoms/Text";
import ContactForm from "../components/forms/ContactForm";
import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { Contact as ContactType } from "../interfaces/contact.interface";
import { handlerApi } from "../service/handlerApi";

const Contact = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/contact", breadcrumb: "Kontak" },
  ];

  const { data } = useFetchData<ContactType>(() =>
    handlerApi(
      "/api/contact-us?populate[0]=contacts.icon&populate[1]=seo.pageBanner&populate[2]=socials.icon&populate[3]=embeddedMapSourceLink"
    )
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType="h1" customClass="text-[#EF841A]">
        {data && data?.attributes.seo.pageTitle}
      </Heading>
      <Container
        customClass="gap-10 my-10"
        orientation="horizontal"
        firstElement={<Text>{data?.attributes.seo.pageDescription}</Text>}
        secondElement={
          data?.attributes.seo.pageBanner ? (
            <Image
              src={
                process.env.REACT_APP_BASE_URL +
                data?.attributes.seo.pageBanner.data.attributes.url
              }
            />
          ) : (
            "Image Not Found"
          )
        }
      />
      <Container
        customClass="gap-10 mt-24"
        orientation="horizontal"
        firstElement={
          <EmbededMapMedia src={data?.attributes.embeddedMapSourceLink!} />
        }
        secondElement={
          <Container
            orientation="vertical"
            firstElement={
              <>
                <Heading
                  headingType="h2"
                  variant="secondary"
                  customClass="font-normal mb-5"
                >
                  Kontak Kami
                </Heading>
                {data
                  ? data?.attributes.contacts.map((contact, index) => {
                      return (
                        <LinkWithIcon
                          key={index}
                          clickableURL={contact.clickableURL}
                          content={contact.content}
                          purpose={contact.for}
                          iconURL={contact.icon.data.attributes.url}
                        />
                      );
                    })
                  : "No data!"}
              </>
            }
            secondElement={
              <>
                <Heading
                  headingType="h2"
                  variant="secondary"
                  customClass="font-normal my-5"
                >
                  Sosial Media Kami
                </Heading>
                {data
                  ? data?.attributes.socials.map((social, index) => {
                      return (
                        <LinkWithIcon
                          key={index}
                          clickableURL={social.clickableURL}
                          content={social.content}
                          purpose={social.for}
                          iconURL={social.icon.data.attributes.url}
                          customClasses="text-[#3BB3C3]"
                        />
                      );
                    })
                  : "No data!"}
              </>
            }
          />
        }
      />
      <Container
        customClass="gap-10 mt-36"
        orientation="horizontal"
        firstElement={
          <>
            <Heading headingType="h2" variant="secondary">
              Anda memiliki pertanyaan ?
            </Heading>
            <Text>kirim pesan, dan kami akan menjawab pertanyaan anda</Text>
          </>
        }
        secondElement={
          <ContactForm />
        }
      />
    </GlobalLayout>
  );
};

export default Contact;
