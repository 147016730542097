import { Link } from "react-router-dom";
import Image from "../atoms/Image";

export type SectionProps = {
  iconSrc?: string;
  for?: string;
  content: string;
  clickableURL?: string;
};

const Section = (props: SectionProps) => {
  return (
    <div>
      {props.iconSrc && <Image src={props.iconSrc} alt={props.for} />}
      <Link to="#">{props.content}</Link>
    </div>
  );
};

export default Section;
