export const logoaruna = require('../icon/logoaruna.png');
export const logo1 = require('../icon/logo1.png');
export const logo2 = require('../icon/logo2.png');
export const logo3 = require('../icon/logo3.png');
export const logo4 = require('../icon/logo4.png');
export const logo5 = require('../icon/logo5.png');
export const logo6 = require('../icon/logo6.png');
export const icon1 = require('../icon/icon1.png');
export const icon2 = require('../icon/icon2.png');
export const icon3 = require('../icon/icon3.png');
export const aruna2 = require('../icon/aruna2.png');
export const hero1 = require('../icon/hero1.png');
export const Symbol = require('../icon/Symbol.png');
export const Vector = require('../icon/Vector.png');
export const Whatsapp = require('../icon/Whatsapp.png');
export const Plane = require('../icon/plan.png');
export const iconpendidikan = require('../icon/icon-pendidikan.png');
export const iconkurikulum = require('../icon/icon-kurikulum.png');
export const iconsahabat = require('../icon/icon-sahabat.png');
export const iconworkshop = require('../icon/shop.png');
export const calendar = require('../icon/calender.png');
export const ig = require('../icon/ig.png');
export const fb = require('../icon/fb.png');
export const twitter = require('../icon/twitter.png');
export const telp = require('../icon/telp.png');
export const sms = require('../icon/sms.png');
