import ReactMarkdown from "react-markdown";

type Props = {
  content?: string;
};

const MarkdownParser = ({ content }: Props) => {
  return content ? (
    <ReactMarkdown>{content}</ReactMarkdown>
  ) : (
    <div>SNAP :/, Error while getting content!</div>
  );
};

export default MarkdownParser;
