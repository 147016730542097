type Props = {
  src: string;
  title?: string;
};

const EmbededMapMedia = ({ src, title }: Props) => {
  const containerClasses = "w-full md:h-[500px] h-80 border-none aspect-square";

  return (
    <>
      {src ? (
        <iframe
          title={title ? title : src}
          className={containerClasses}
          src={src}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      ) : (
        <div className={containerClasses}>No Link!</div>
      )}
    </>
  );
};

export default EmbededMapMedia;
