import { bglayanan } from "../../assets/img/galery";

type LayananProps = {
  Layanan: {
    sectionTitle: string;
    sectionContent: string;
    sectionIcon: string;
  }[];
};

const Layanan = ({ Layanan }: LayananProps) => {
  return (
    <div className="pt-5 mb-10 md:mb-16 flex justify-center items-center overflow-hidden rounded-b-none relative flex-col">
      <div>
        <img
          src={bglayanan}
          alt="bglayanan"
          className="h-[300px] w-full sm:h-[500px] md:h-[600px] lg:h-full"
        />
      </div>
      <div className="flex absolute mt-[-240px] sm:mt-[-400px]  ">
        <h3 className="text-white text-2xl sm:text-[48px] font-andika">
          Layanan Kami
        </h3>
      </div>
      <div className="flex-row flex  absolute px-10">
        {Layanan.map((service, index) => (
          <div
            className="bg-white rounded-xl   sm:shadow-lg  items-center flex flex-col sm:pt-[32px] pt-[12px] sm:px-[20px] px-[10px] sm:pb-[50px] pb-[20px] sm:mx-8 mx-4"
            key={index}
          >
            <div>
              <img
                src={process.env.REACT_APP_BASE_URL + service.sectionIcon}
                alt="service logo"
                className="sm:w-[176.812px] w-[500px] h-[25px] object-contain sm:h-[100px] "
              />
            </div>
            <div className="text-center ">
              <h3 className="text-[15px] sm:text-[24px] mt-2">
                {service.sectionTitle}
              </h3>
              <p className="sm:text-[16px] text-[10px]  mt-2 w-[120px] sm:w-[200px]">
                {service.sectionContent}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Layanan;
