import "../index.css";
import Accordion from "./Accordion";

type FaqProps = {
  faq: {
    title: string;
    content: string;
  }[];
};

const Faq = ({ faq }: FaqProps) => {
  return (
    <div
      id="faq"
      className="mb-5 font-andika justify-center items-center mt-10 md:mt-16 px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]"
    >
      <div className="my-5 text-center">
        <p className="sm:text-[36px] text-[28px] ">Frequently Asked Question</p>
      </div>
      <div className="list">
        {faq.map((item, index) => (
          <Accordion
            key={index}
            datas={{
              answer: item.content,
              question: item.title,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
