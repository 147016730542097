import { useState } from "react";
import Input from "../atoms/Input";
import Heading from "../atoms/Heading";
import TextArea from "../atoms/TextArea";
import Button from "../atoms/Button";
import Swal from "sweetalert2";
import { HTTPAruna } from "../../service/handlerApi";

type ContactFormData = {
  fullName: string;
  email: string;
  phoneNumber: string; // Make sure phoneNumber is always a string
  messageContent: string;
};

const ContactForm = () => {
  const [contactFormData, setContactFormData] = useState<ContactFormData>({
    email: "",
    fullName: "",
    messageContent: "",
    phoneNumber: "", // Initialize phoneNumber as an empty string
  });
  const [emailError, setEmailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");
  const isFormValid =
    !emailError &&
    !phoneError &&
    contactFormData.fullName &&
    contactFormData.messageContent &&
    contactFormData.phoneNumber; // Make sure phoneNumber is not empty

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phonePattern = /^[1-9][0-9]*$/;
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError("Phone number format: 82123456789");
    } else {
      setPhoneError("");
    }
  };

  const handleEmailChange = (newValue: string) => {
    setContactFormData((prevData) => ({
      ...prevData,
      email: newValue,
    }));
    validateEmail(newValue);
  };

  const handlePhoneNumberChange = (newValue: string) => {
    setContactFormData((prevData) => ({
      ...prevData,
      phoneNumber: newValue,
    }));
    validatePhoneNumber(newValue);
  };

  const submitForm = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isFormValid) {
      HTTPAruna.post("/api/contact-forms", { data: contactFormData })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success submit Contact Form!",
            text: "Terimakasih telah mengirim pesan, kami akan menghubungi kembali segera!",
            timer: 3000,
          });
          setContactFormData({
            email: "",
            fullName: "",
            messageContent: "",
            phoneNumber: "", // Initialize phoneNumber as an empty string
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Failed submit Contact Form!",
            text: "Mohon cek kembali data yang anda masukkan ya! " + error,
          });
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed submit Contact Form!",
        text: "Mohon cek kembali data yang anda masukkan ya!",
      });
    }
  };

  return (
    <form className="pt-7 pb-16 px-5 lg:px-20 border rounded-3xl">
      <Heading headingType="h2" variant="secondary" customClass="text-center">
        Hubungi Kami
      </Heading>
      <section className="grid gap-4 mt-8">
        <Input
          type="text"
          placeholder="Masukkan Nama Lengkap"
          onChange={(e) => {
            setContactFormData((prevData) => ({
              ...prevData,
              fullName: e.target.value,
            }));
          }}
          value={contactFormData.fullName}
        />
        <Input
          type="text"
          placeholder="Masukkan Email"
          onChange={(e) => handleEmailChange(e.target.value)}
          value={contactFormData.email}
          error={emailError}
        />
        <Input
          type="number"
          placeholder="Masukkan No. Handphone"
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
          value={contactFormData.phoneNumber}
          error={phoneError}
        />
        <TextArea
          onChange={(e) => {
            setContactFormData((prevData) => ({
              ...prevData,
              messageContent: e.target.value,
            }));
          }}
          placeholder="Tulis Pesan"
          value={contactFormData.messageContent}
        />
        <Button
          buttonType="button"
          action={submitForm}
          isDisabled={!isFormValid}
        >
          Kirim Pesan
        </Button>
      </section>
    </form>
  );
};

export default ContactForm;
