import React, { ChangeEvent } from "react";

type InputProps = {
  type?: "text" | "number" | "email";
  placeholder?: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  customClasses?: string;
};

const Input: React.FC<InputProps> = ({
  type = "text",
  placeholder,
  value,
  onChange,
  error,
  customClasses,
}) => {
  const finalClasess = `w-full px-5 py-3 border border-[#E0E0E0] rounded-lg ${customClasses}`;

  return (
    <>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={finalClasess}
      />
      {error && <div className="text-red-500 py-2">{error}</div>}
    </>
  );
};

export default Input;
