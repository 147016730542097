import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { handlerApi } from "../service/handlerApi";
import { Service } from "../interfaces/Service.interface";
import { useParams } from "react-router-dom";
import Heading from "../components/atoms/Heading";
import Container from "../components/atoms/Container";
import Image from "../components/atoms/Image";
import { Plane, calendar } from "../assets/img/icon";
import { formatDate } from "../utils/dateFormatter";
import WorkshopRegForm from "../components/forms/WorkshopRegForm";

const WorkShopDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/workshop", breadcrumb: "Workshop" },
    {
      path: "/workshop/" + slug,
      breadcrumb: slug ? slug?.replace(",", " ") : "No Data",
    },
  ];

  const { data } = useFetchData<Service[]>(() =>
    handlerApi(`/api/services?populate=*&filters[slug][$eq]=${slug}`)
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      {data && data?.length > 0 ? (
        <>
          <Container
            orientation="horizontal"
            firstElement={
              <figure>
                {data[0].attributes.image && (
                  <Image
                    src={
                      process.env.REACT_APP_BASE_URL! +
                      data[0].attributes.image.data.attributes.url
                    }
                    alt={data[0].attributes.serviceTitle}
                  />
                )}
              </figure>
            }
            secondElementClasses="grid gap-5"
            secondElement={
              <>
                <Heading headingType="h3">
                  {data[0].attributes.serviceTitle}
                </Heading>
                <div className="flex gap-5">
                  <Image
                    src={calendar}
                    alt={"calendar " + data[0].attributes.serviceTitle}
                  />
                  <span>{formatDate(data[0].attributes.eventStart)}</span>
                </div>
                {data[0].attributes.content}
              </>
            }
          />
          <article>
            <div className="flex gap-3 my-10">
              <Image customClass="object-none" src={Plane} />
              <Heading headingType="h2">Persyaratan Pendaftaran</Heading>
            </div>
            {data[0].attributes.termsAndCondition}
            <WorkshopRegForm />
          </article>
        </>
      ) : (
        <div className="w-full h-full">
          <Heading headingType="h1">
            Workshop not found with url: <code>{"workshop/" + slug}</code>
          </Heading>
        </div>
      )}
    </GlobalLayout>
  );
};

export default WorkShopDetail;
