import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Kontak from "./pages/Contact";
import Tentang from "./pages/AboutPage";
import Artikel from "./pages/ArticlePage";
import Arunasdc from "./pages/ArunaSDCPage";
import Rumaharuna from "./pages/RumahArunaPage";
import Detailartikel from "./components/artikels/Detailartikel";
import Detailprogram from "./components/rumaharuna/Detailprogram";
import WorkShopPage from "./pages/WorkShopPage";
import WorkShopDetail from "./pages/WorkShopDetail";
import CurriculumPage from "./pages/Kurikulum";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "tentang",
        element: <Tentang />,
      },
      {
        path: "contact",
        element: <Kontak />,
      },
      {
        path: "artikel",
        element: <Artikel />,
      },
      {
        path: "artikel/:slug",
        element: <Detailartikel />,
      },
      {
        path: "rumah-aruna",
        element: <Rumaharuna />,
      },
      {
        path: "rumah-aruna/program",
        element: <Detailprogram />,
      },
      {
        path: "curriculum",
        element: <CurriculumPage />,
      },
      {
        path: "aruna-sdc",
        element: <Arunasdc />,
      },
      {
        path: "workshop",
        element: <WorkShopPage />,
      },
      {
        path: "workshop/:slug",
        element: <WorkShopDetail />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
