import { Link } from "react-router-dom";
import GlobalLayout from "../components/layouts/GlobalLayout";
import ButtonPagination from "../components/Button/ButtonPagination";
import useArticleWithPagination from "../hooks/useArticleWithPagination";

const ArticlePage = () => {
  const {
    data,
    paginationMeta,
    currentPage,
    error,
    handleNextClick,
    handlePrevClick,
    loading,
  } = useArticleWithPagination(1, 3);

  if (error) return <div className="h-screen">Article Not Found</div>;

  const handleNavClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/artikel", breadcrumb: "Artikel" },
  ];
  const showPagination =
    paginationMeta?.pageCount && paginationMeta?.pageCount > 1;

  return (
    <GlobalLayout breadCrumbs={bc}>
      <div className="">
        <p className="sm:text-[36px] text-2xl  sm:py-10 pt-5 text-[#EF841A]">
          Artikel
        </p>
        {data && loading ? (
          <div className="h-screen">Loading...</div>
        ) : (
          data?.map((artikel) => (
            <div
              key={artikel.id}
              className="flex flex-col md:flex-row gap-y-5 md:gap-y-10 my-10"
              onClick={handleNavClick}
            >
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  artikel.attributes.featuredImage.data.attributes.url
                }
                alt="artikel"
                className="rounded-md md:min-w-[387px] lg:min-w-[487px] md:h-[315.75px] w-full h-[200px] object-cover object-center"
              />
              <div className="sm:mx-10 pt-5">
                <p className="text-gray-400 xl:text-[16px] text-xs">
                  {artikel.attributes.excerpt}
                </p>
                <h3 className="sm:text-[32px] text-[20px] font-bold my-2 sm:my-5 line-clamp-3">
                  {artikel.attributes.articleTtitle}
                </h3>
                <p className="text-[15px] text-justify k sm:text-[16px] line-clamp-3">
                  {artikel.attributes.content}{" "}
                </p>
                <Link
                  to={`${artikel.attributes.slug}`}
                  className="text-blue-400"
                >
                  Baca selengkapnya
                </Link>
              </div>
            </div>
          ))
        )}
        {showPagination && (
          <ButtonPagination
            handlePrev={handlePrevClick}
            handleNext={handleNextClick}
            page={currentPage}
            totalPages={paginationMeta?.pageCount || 1}
          />
        )}
      </div>
    </GlobalLayout>
  );
};

export default ArticlePage;
