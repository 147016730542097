import { BreadCrumbType } from "../../@types/BreadCrumbType";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

type Props = {
  children: React.ReactNode;
  breadCrumbs: BreadCrumbType[];
};

const GlobalLayout = ({ children, breadCrumbs }: Props) => {
  return (
    <>
      <main className="w-full flex flex-col justify-center items-center font-andika bg-white py-32 lg:py-50">
        {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
        <div className="px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]">
          {children}
        </div>
      </main>
    </>
  );
};

export default GlobalLayout;
