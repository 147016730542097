import { Program } from "../../interfaces/rumahAruna.interface";
import Button from "../atoms/Button";
import Heading from "../atoms/Heading";
import Image from "../atoms/Image";

type Props = {
  programs: Program[];
};

const ProgramCard = ({ programs }: Props) => {
  return (
    <>
      {programs.map((program, index) => {
        return (
          <article
            key={index}
            className="my-5 flex p-8 w-full flex-row-reverse rounded-3xl shadow-md gap-5"
          >
            <section className="w-1/2">
              <Heading headingType="h3">
                {program.attributes.programTitle}
              </Heading>
              <div>
                <Button
                  buttonType="Link"
                  variant="primary"
                  link={program.attributes.clickableURLAction}
                >
                  <span>Konsultasi Sekarang</span>
                </Button>
                <Button
                  isExternal
                  buttonType="Link"
                  variant="secondary"
                  link={
                    process.env.REACT_APP_BASE_URL! +
                    program.attributes.downloadableMedias.data[0].attributes.url
                  }
                >
                  <span>
                    {JSON.stringify(
                      program.attributes.downloadableMedias.data[0].attributes
                        .url
                    )}
                  </span>
                </Button>
              </div>
            </section>
            <section className="bg-red">
              <Image
                customClass="w-[300px] h-[200px] items-left relative rounded-xl"
                src={
                  process.env.REACT_APP_BASE_URL +
                  program.attributes.featuredImage.data.attributes.url
                }
              />
            </section>
          </article>
        );
      })}
    </>
  );
};

export default ProgramCard;
