import Image from "../atoms/Image";

type InfoProps = {
  info: {
    sectionTitle: string;
    sectionContent: string;
    sectionIcon: string;
  }[];
};

const Info = ({ info }: InfoProps) => {
  return (
    <div className="mb-10 md:mb-16 px-5 sm:px-12 lg:px-20 py-5 w-full max-w-[1440px]">
      <div className="font-andika sm:m-8 flex flex-wrap justify-center lg:justify-around items-center">
        {info.map((item, index) => (
          <div
            key={index}
            className="bg-white w-full md:w-auto rounded-md mx-2 mt-5 lg:mt-0 sm:px-0 px-5 md:px-3 lg:mx-10 py-[25px] sm:py-[42px] shadow-lg flex flex-col gap-x-10 items-center"
          >
            <Image
              src={process.env.REACT_APP_BASE_URL + item.sectionIcon}
              alt={`icon${index + 1}`}
              customClass="sm:w-[76px] sm:h-[76px] w-[45px] h-[45px]"
            />

            <div className="text-center  sm:w-[210px]">
              <h3 className="text-[12px] sm:text-[20px] mt-2">
                {item.sectionTitle}
              </h3>
              <p className="text-xs sm:text-[12px] mt-2 w-auto">
                {item.sectionContent}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Info;
