import Heading from "../components/atoms/Heading";
import Image from "../components/atoms/Image";
import GlobalLayout from "../components/layouts/GlobalLayout";
import { useFetchData } from "../hooks/fetchData";
import { Service } from "../interfaces/Service.interface";
import { handlerApi } from "../service/handlerApi";
import Button from "../components/atoms/Button";
import { formatDate } from "../utils/dateFormatter";
import { calendar } from "../assets/img/icon";

const WorkShopPage = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/workshop", breadcrumb: "Workshop" },
  ];

  const { data } = useFetchData<Service[]>(() =>
    handlerApi("/api/services?populate=*")
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType="h1" customClass="text-center my-16">
        Workshop Mendatang
      </Heading>
      <article className="grid grid-cols-2 gap-8">
        {data ? (
          data.map((service, index) => {
            return (
              <div
                className="py-8 px-2 grid grid-cols-2 shadow-md rounded-xl"
                key={index}
              >
                <figure>
                  {service.attributes.image && (
                    <Image
                      src={
                        process.env.REACT_APP_BASE_URL! +
                        service.attributes.image.data.attributes.url
                      }
                      alt={service.attributes.serviceTitle}
                    />
                  )}
                </figure>
                <div className="flex flex-col justify-between gap-5">
                  <Heading headingType="h3">
                    {service.attributes.serviceTitle}
                  </Heading>
                  <div className="flex gap-5">
                    <Image
                      src={calendar}
                      alt={"calendar " + service.attributes.serviceTitle}
                    />
                    <span>{formatDate(service.attributes.eventStart)}</span>
                  </div>
                  <Button
                    buttonType="Link"
                    link={`/workshop/${service.attributes.slug}`}
                    variant="primary"
                    customClasses="text-white hover:text-[#2e2e2e] border-2 text-center"
                  >
                    Daftar Sekarang!
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div>There is no upcoming Workshop</div>
        )}
      </article>
    </GlobalLayout>
  );
};

export default WorkShopPage;
