import Kegiatanaruna from "../components/rumaharuna/Kegiatanaruna";
import Kurikulumaruna from "../components/rumaharuna/kurikulumaruna";
import GlobalLayout from "../components/layouts/GlobalLayout";
import Heading from "../components/atoms/Heading";
import Container from "../components/atoms/Container";
import { useFetchData } from "../hooks/fetchData";
import { RumahAruna } from "../interfaces/rumahAruna.interface";
import { handlerApi } from "../service/handlerApi";
import Image from "../components/atoms/Image";
import Text from "../components/atoms/Text";
import { Plane } from "../assets/img/icon";
import MarkdownParser from "../components/atoms/MarkdownParser";
import ProgramCard from "../components/rumaharuna/ProgramCard";

const RumahArunaPage = () => {
  const bc = [
    { path: "/", breadcrumb: "Beranda" },
    { path: "/profile-aruna", breadcrumb: "Profil Rumah Aruna" },
  ];

  const { data } = useFetchData<RumahAruna>(() =>
    handlerApi(
      "/api/service-page?populate[0]=sections.sectionIcon&populate[1]=seo.pageBanner&populate[2]=curricula.icon&populate[3]=programs.downloadableMedias&populate[4]=programs.featuredImage&populate[5]=infoSections"
    )
  );

  return (
    <GlobalLayout breadCrumbs={bc}>
      <Heading headingType="h1">{data?.attributes.seo.pageTitle}</Heading>
      <Container
        customClass="gap-10"
        orientation="horizontal"
        firstElement={<Text>{data?.attributes.seo.pageDescription}</Text>}
        secondElement={
          data?.attributes.seo.pageBanner ? (
            <Image
              src={
                process.env.REACT_APP_BASE_URL +
                data?.attributes.seo.pageBanner.data.attributes.url
              }
            />
          ) : (
            "Image Not Found"
          )
        }
      />
      <Kurikulumaruna />
      {data?.attributes.infoSections
        ? data.attributes.infoSections.map((infoSection, index) => {
            return (
              <Container
                key={index}
                customClass="gap-y-5"
                firstElement={
                  <div className="flex gap-3">
                    <Image customClass="object-none" src={Plane} />
                    <Heading headingType="h2">{infoSection.title}</Heading>
                  </div>
                }
                secondElement={<MarkdownParser content={infoSection.content} />}
              />
            );
          })
        : "Data not found"}
      {data?.attributes.programs ? (
        <ProgramCard programs={data.attributes.programs.data!} />
      ) : (
        "Program not found"
      )}
      <Kegiatanaruna />
    </GlobalLayout>
  );
};

export default RumahArunaPage;
