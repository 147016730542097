import { Link } from "react-router-dom";
import { BreadCrumbType } from "../../@types/BreadCrumbType";

type Props = {
  breadCrumbs: BreadCrumbType[];
};

const BreadCrumbs = ({ breadCrumbs }: Props) => {
  return (
    <div className="w-full bg-[#EF841A] mt-[-24px]">
      <div className="max-w-[1440px] mx-auto py-7 px-5 sm:px-12 lg:px-20">
        {breadCrumbs.map((breadCrumb, index) => {
          return (
            <Link
              key={index}
              to={breadCrumb.path}
              className=" text-white text-[16px]"
            >
              {breadCrumb.breadcrumb}{" "}
              {index + 1 !== breadCrumbs.length && <span> &gt; </span>}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BreadCrumbs;
